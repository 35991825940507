<template>
	<LoginLayout
		form-title="SSO Enabled for this domain"
	>
		<template #form>
			<Message>
				Please log in using your 'Single Sign On' details
			</Message>
			<a @click="$router.push({ path: '/' })">
				Please click here to return to the login screen.
			</a>
		</template>
	</LoginLayout>
</template>
<script>
import LoginLayout from '../layouts/login-layout.vue'
import Message from './message.vue'
export default {
	components: { Message, LoginLayout }
}
</script>
